import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { ModalRoutingContext, Link } from "gatsby-plugin-modal-routing-3"

const ModalBox = styled.div`
  box-shadow: unset !important;
  width: 100%;

  h1,
  h3 {
    color: white;
  }

  .image-box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 50vw;
    margin: 0 auto 0 0;
    padding: 20px;
    background: var(--cube-grad-blue);
    box-shadow: var(--cube-box-shadow);
  }

  .image {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
  }

  .content-box {
    width: 45vw;
    margin: -36px auto 0 35%;
    background: white;
    padding: 20px;
    box-shadow: var(--cube-box-shadow);

    @media (max-width: 768px) {
      width: 70vw;
      margin: -21px auto 0 10%;
    }
  }

  .close-button {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;

    > div {
      background-color: #fff;
      transform: rotate(45deg);
      width: 30px;
      height: 3px;
      transition: all 300ms;

      ::after {
        width: 30px;
        height: 3px;
        background-color: #fff;
        content: "";
        position: absolute;
        transform: rotate(-90deg);

        :hover {
          transform: scale(1.3) rotate(-90deg);
        }
      }
      :hover {
        transform: scale(1.3) rotate(45deg);
      }
    }
  }
`

const Member = ({ data }) => {
  const { name, title, image } = data.markdownRemark.frontmatter
  const html = data.markdownRemark.html

  return (
    <ModalBox>
      <div>
        <div>
          <div className="image-box">
            <div className="image">
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                alt={name}
                style={{ maxWidth: "300px", width: "-webkit-fill-available" }}
              />
              <ModalRoutingContext.Consumer>
                {({ closeTo }) => (
                  <Link to={closeTo}>
                    <div className="close-button">
                      <div />
                    </div>
                  </Link>
                )}
              </ModalRoutingContext.Consumer>
            </div>
            <h1>{name}</h1>
            <h3>{title}</h3>
          </div>
          <div className="content-box">
            <p dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
      </div>
    </ModalBox>
  )
}

export const pageQuery = graphql`
  query MemberBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        name
        title
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [PNG, WEBP, AVIF]
              layout: CONSTRAINED
              width: 300
              height: 300
              transformOptions: { cropFocus: CENTER, grayscale: true }
            )
          }
        }
      }
    }
  }
`

export default Member
